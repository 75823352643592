import styled from 'styled-components';

export const ProposalPageContainer = styled.div`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div:first-child {
    padding-bottom: 3.125rem;
  }
`;
