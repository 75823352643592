import { captureMessage } from '@sentry/node';
import { PopulatedAnswer } from 'Shared/types/contribution';
/*
 * This function is currently a workaround for manually added 'custom' contributions to a map.
 * Will be deprecated once we do either:
 * 1. Change textpoll answer to be string instead of array of 1 strings
 * 2. Change handling to xyz map filters to query for an array of strings
 */

export const buildAnswersForMapFilters = (
  contributionId: string,
  answersPopulated: PopulatedAnswer[]
): PopulatedAnswer[] => {
  const parsedAnswersPopulated = answersPopulated.map((answer) => {
    if (answer.questionContent.type === 'textpoll') {
      if ((answer?.value as Array<string>)?.length > 1) {
        captureMessage(
          `Textpoll answer with more than 1 value while sending to postgres: ${contributionId} - ${JSON.stringify(
            answer.value
          )}`,
          {
            extra: {
              contributionId: contributionId,
              answer: answer.value,
            },
          }
        );
      }
      return {
        ...answer,
        value: answer.value?.[0] || answer.value,
      };
    }
    return answer;
  });
  return parsedAnswersPopulated;
};
