import * as React from 'react';
import {
  ProposalSection,
  Question,
  QuestionValue,
} from 'Client/pages/proposals';
import { ProposalSectionRender } from 'Client/pages/proposals/components';
import { useProposalContext } from 'Client/utils/hooks';

export const ContributionFlowSectionsCf3: React.FC<{
  steps: ProposalSection<Question>[];
  proposalSlug: string;
  handleChange: (questionId: string, value: QuestionValue) => void;
  showMinutesLeft?: boolean;
}> = ({ steps, proposalSlug, handleChange, showMinutesLeft }) => {
  const [{ answers }] = useProposalContext();

  React.useEffect(() => {
    console.log('ContributionFlowSectionsCf3 - steps: ', steps);
    console.log('ContributionFlowSectionsCf3 - steps.length: ', steps.length);
  }, []);

  if (!steps.length) return null;

  return (
    <>
      {steps.map(({ question, sections }, index) => (
        // nestedSections ? (
        <div
          id={`step${index + 1}`}
          key={`step${index + 1}`}
          // data-testid={`step${index + 1}`}
          data-testid={'cf-proposal-step'}
          className={proposalSlug}
        >
          {sections
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((nestedSection, nestedIndex) => {
              return (
                <>
                  <ProposalSectionRender
                    backgroundColour={nestedSection.backgroundColour}
                    key={nestedIndex}
                    title={nestedSection.title}
                    description={nestedSection.description}
                    question={nestedSection.question}
                    value={answers[nestedSection.question?.id]}
                    // voiceValue={
                    //   voiceAnswers[nestedSection.question?.id] as string
                    // }
                    steps={steps}
                    handleChange={handleChange}
                    image={nestedSection.image}
                    imageComparisonSlider={nestedSection.imageComparisonSlider}
                    imageAndGrid={nestedSection.imageAndGrid}
                    accordionContent={nestedSection.accordionContent}
                    twoColumns={nestedSection.twoColumns}
                    showProgressBar={nestedIndex === 0}
                    showMinutesLeft={
                      typeof showMinutesLeft === 'boolean'
                        ? showMinutesLeft
                        : true
                    }
                    // hideQuestion={hideQuestion}
                    files={nestedSection.files}
                    pagePreview={nestedSection.pagePreview}
                    navMap={nestedSection.navMap}
                    imageMapPro={nestedSection.imageMapPro}
                    sectionId={question?.id ?? nestedSection?._id}
                  />
                </>
              );
            })}
        </div>
      ))}
    </>
  );
};
