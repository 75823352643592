import * as React from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

interface SectionWrapperProps {
  children: React.ReactNode;
  currentStep?: string;
  possibleSteps: string[];
  isMap: boolean;
}

export const SectionWrapperCf3: React.FC<SectionWrapperProps> = ({
  children,
  currentStep,
  possibleSteps,
  isMap,
}: SectionWrapperProps) => {
  // The logic of what section to show/hide is here
  const { query } = useRouter();
  console.log('🚀 ~ query:', query);
  const visibleStep = currentStep;
  console.log('🚀 ~ visibleStep:', visibleStep);
  const proposalSlug = isMap ? String(query?.slug) : query?.slug[0];
  console.log('🚀 ~ proposalSlug:', proposalSlug);
  console.log(
    '🚀 ~ possibleSteps.includes(visibleStep):',
    possibleSteps.includes(visibleStep)
  );

  if (!possibleSteps.includes(visibleStep)) return <>Invalid step!</>;
  return (
    <StyledSectionWrapper
      visibleStep={visibleStep}
      proposalSlug={proposalSlug}
      data-testid="proposal-section-wrapper"
      data-visible-step={visibleStep}
    >
      {children}
    </StyledSectionWrapper>
  );
};

const StyledSectionWrapper = styled.div<{
  visibleStep: string;
  proposalSlug: string;
}>`
  ${({ visibleStep, proposalSlug }) =>
    visibleStep &&
    proposalSlug &&
    css`
      > div {
        width: 100%;
        height: fit-content;
        padding-bottom: 3.5rem;

        @media (max-width: 959px) {
          padding-bottom: 2.5rem;
        }
      }

      > div:not(#${visibleStep}.${proposalSlug}) {
        z-index: -100;
        display: none;
      }

      > div#${visibleStep}.${proposalSlug} {
        z-index: 100;
      }
    `}
`;
