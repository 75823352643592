import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { EmailCircleIcon, PhoneIcon } from 'Atoms/Icons';
import { HubHeader } from 'Client/components/organisms';
import {
  socialMediasContent,
  SOCIAL_MEDIAS,
} from 'Client/constants/socialMedias';
import { ContactTeamProps } from './types';
import {
  Container,
  SocialMediaWrapper,
  ContactInfoWrapper,
  LabelWrapper,
  IconTextItem,
} from './ContactTeam.styles';

export const ContactTeam: React.FC<ContactTeamProps> = ({ content }) => {
  const { t } = useTranslation();
  const { title, description, socialMedias, email, phone } = content;

  return (
    <>
      <HubHeader title={title || t('Contact the project team')} />
      <Container>
        <Typography>{description}</Typography>
        <SocialMediaWrapper>
          {Object.keys(socialMedias || {}).map((value: SOCIAL_MEDIAS) => {
            return (
              <a
                key={value}
                target="_blank"
                href={socialMedias[value]}
                rel="noreferrer"
              >
                {socialMediasContent[value]?.icon}
              </a>
            );
          })}
        </SocialMediaWrapper>
        <ContactInfoWrapper>
          {typeof email !== 'undefined' && (
            <IconTextItem>
              <LabelWrapper>
                <EmailCircleIcon />
                <Typography>{<span>{t('Email:')}</span>}</Typography>
              </LabelWrapper>

              <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                {email}
              </a>
            </IconTextItem>
          )}

          {typeof phone !== 'undefined' && (
            <IconTextItem>
              <LabelWrapper>
                <PhoneIcon width={25} height={25} />
                <Typography>{<span>{t('Phone:')}</span>}</Typography>
              </LabelWrapper>

              <Typography>{phone}</Typography>
            </IconTextItem>
          )}
        </ContactInfoWrapper>
      </Container>
    </>
  );
};
