import * as React from 'react';
import { captureException } from '@sentry/node';
import Box from '@material-ui/core/Box';
import { Trans, useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { FormContainer, InitI18n, LoadingButtonStates } from 'Atoms';
import { Checkbox, HtmlDescription, TextField } from 'Molecules';
import LoadingScreen from 'Client/components/molecules/LoadingScreen/LoadingScreen';
import { CircleClose, EmailIcon, EmailOpenIcon } from 'Icons';
import {
  validateEmail as validateEmailRegex,
  parseSyntaxValidationResult,
} from 'Client/utils/validators';
import { CommentContribution } from 'Shared/classes/Contribution/Contribution';
import { sendConfirmationEmail } from 'Client/services/email';
import { titleCase } from 'Client/utils/stringManipulations';
import { cpBrandName } from 'Client/constants/brand';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { syntaxValidationRequest } from 'Client/services/validation';
import {
  Content,
  EmailPageContainer,
  Header,
  InputLabel,
  NextButton,
} from './EmailPage.styles';
import { BottomPanel } from './components';
import { PrivacyPolicyNotice } from './components/PrivacyPolicyNotice';
import {
  ConfirmButton,
  ConsentCheckbox,
  ContentContainer,
  EmailBlock,
  EmailBottomSection,
  EmailIconWithText,
  EmailTopSection,
  HeaderContent,
  HeaderText,
  InfoBlock,
  InfoItem,
  InfoItemContainer,
  Wrapper,
} from './EmailPageCf3.styles';
import { RoundInput } from '../edit/components/Form';

export interface EmailPageProps {
  customPageProps?: { title: string; description: string };
  contribution: CommentContribution;
  changeStep?: (direction: 'backwards' | 'forwards', step?: string) => void;
}

export const EmailPageCf3: React.FC<EmailPageProps> = ({
  customPageProps,
  contribution,
  changeStep,
}) => {
  const project = useProject();
  const titleCasedProjectName = titleCase(project.name);
  const contributionEmail = contribution?.dataHandlers?.user?.email;
  const { t, i18n } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [emailInputValue, setEmailInputValue] = React.useState('');
  console.log(
    '🚀 ~ contribution?.dataHandlers?.user?.email:',
    contributionEmail
  );
  React.useEffect(() => {
    if (emailInputValue && contributionEmail === emailInputValue) return;
    setEmailInputValue(contributionEmail);
  }, [contributionEmail]);

  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [emailValid, setEmailValid] = React.useState(
    emailInputValue && contributionEmail === emailInputValue
  );
  const [emailValidationStatus, setEmailValidationStatus] = React.useState<{
    type: 'error' | 'warning';
    message: React.ReactNode;
  } | null>(null);
  const handleEmailInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    console.log('email input change', e.target.value);
    setSubmitWithForce(false);
    setEmailValidationStatus(() => null);
    setEmailInputValue(e.target.value?.toLowerCase());
  };

  const handleNextClick = () => {
    changeStep('forwards');
  };

  const emailValidation = async (email?: string) => {
    // Email block validated the email already OR is an existing user which is already validated too
    if (emailInputValue && contributionEmail === emailInputValue) {
      setEmailValidationStatus(null);
      setEmailValid(true);
      return;
    }
    const regexValidation = validateEmailRegex(email);

    if (!regexValidation) {
      setEmailValidationStatus({
        type: 'error',
        message: t('This does not appear to be a valid email address'),
      });
      return false;
    }
    const sendGridValidation = await syntaxValidationRequest({
      data: email,
    }).then(parseSyntaxValidationResult(t));
    if (sendGridValidation) {
      setEmailValidationStatus(sendGridValidation);
      setSubmitWithForce(sendGridValidation.type === 'warning');
    }
    return !sendGridValidation;
  };

  const validateEmail = async (email?: string) => {
    try {
      setIsSubmitting(true);
      const _email = email || emailInputValue;
      if (!_email) {
        setEmailValidationStatus({
          type: 'error',
          message: t('Please provide an email address'),
        });
        setEmailValid(false);
        return;
      }
      setEmailValid(false); // prevent continue until request is completed
      const ignoreValidation = !_email || submitWithForce;
      const emailValidationRes = ignoreValidation
        ? true
        : await emailValidation(_email); /* proper validation */
      setEmailValid(emailValidationRes);
      if (emailValid) {
        const userAssign = await contribution.assignUser(null, _email);
        if (userAssign.success) {
          await sendConfirmationEmail({
            email: emailInputValue,
            contributionId: contribution._id,
            contributionType: contribution.type,
            lang: userAssign?.user?.language || i18n.language,
            cf3: true,
          });
        }
      }
    } catch (e) {
      console.error(e);
      captureException(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (customPageProps?.title && customPageProps?.description) {
    return (
      <EmailPageContainer>
        {isSubmitting && <LoadingScreen withLoadingRing={true} />}
        <InitI18n />
        <Content>
          <FormContainer>
            <Header
              dangerouslySetInnerHTML={{ __html: customPageProps.title }}
            />
            <HtmlDescription>{customPageProps.description}</HtmlDescription>
            {!project.features.doNotAskForEmail && (
              <>
                <Box mt={3}>
                  <EmailOpenIcon />
                </Box>
                {isSubmitting && <LoadingScreen withLoadingRing={true} />}
                <Box mt={2} textAlign="left">
                  <InputLabel inputId="email">{t('Email')}</InputLabel>
                  <TextField
                    data-testid="EmailPage-email-input-field"
                    id="email"
                    placeholder={t('e.g. sam@smith.com')}
                    label={t('E-mail')}
                    width="100%"
                    type="email"
                    status={emailValidationStatus}
                    value={emailInputValue}
                    handleChange={handleEmailInputChange}
                  />
                  <PrivacyPolicyNotice />
                  <NextButton
                    data-testid="EmailPage-next-button"
                    onClick={handleNextClick}
                    disabled={isSubmitting}
                    resetState={() => {}}
                    state={
                      isSubmitting
                        ? LoadingButtonStates.LOADING
                        : LoadingButtonStates.INITIAL
                    }
                  >
                    {t('Next')}
                  </NextButton>
                </Box>
              </>
            )}
          </FormContainer>
          {!project.features.doNotAskForEmail && <BottomPanel />}
        </Content>
      </EmailPageContainer>
    );
  }
  return (
    <Wrapper>
      {isSubmitting && <LoadingScreen withLoadingRing={true} />}

      <HeaderContent>
        <HeaderText>
          <h1>
            {t('Provide your email address to confirm your contribution')}
          </h1>
          <p>
            {t(
              'Unlock full participation and enhance your impact by providing your email'
            )}
          </p>
        </HeaderText>
        <img
          src="/static/illustrations/email-confirmed-phone.png"
          width="125"
        />
      </HeaderContent>

      <ContentContainer>
        <EmailBlock>
          <EmailTopSection>
            <InputLabel>{t('Email')}</InputLabel>
            <RoundInput
              data-testid="EmailPage-email-input-field"
              id="email"
              placeholder={t('e.g. sam@smith.com')}
              type="email"
              status={emailValidationStatus}
              value={emailInputValue}
              onChange={handleEmailInputChange}
              onBlur={() => validateEmail()}
            />
            <ConsentCheckbox>
              <Checkbox />

              <Trans>
                <p>
                  Receive news and replies about {titleCasedProjectName} and
                  hear about {cpBrandName} in your area. Read{' '}
                  <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                    {cpBrandName}’s privacy policy
                  </a>{' '}
                  or get in touch with any <a>questions</a>.
                </p>
              </Trans>
            </ConsentCheckbox>
          </EmailTopSection>
          <EmailBottomSection>
            <ConfirmButton
              data-testid="EmailPage-next-button"
              onClick={() => validateEmail()}
              disabled={submitWithForce ? false : !emailValid || isSubmitting}
            >
              {t('Get confirmation link')}
            </ConfirmButton>
            <EmailIconWithText>
              <EmailIcon />
              <b>
                {t(
                  "We'll send you an email with a link to confirm your response"
                )}
              </b>
            </EmailIconWithText>
          </EmailBottomSection>
        </EmailBlock>
        <InfoBlock>
          <h4>{t('What happens if you don’t give your email? ')}</h4>
          <InfoItemContainer>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "We won't know if you’re a legitimate contributor and your contribution may not be fully considered, limiting your impact on shaping the community."
                )}
              </p>
            </InfoItem>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "Without your email address, other users won't have the chance to engage with or support your ideas."
                )}
              </p>
            </InfoItem>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "You'll miss out on important updates and progress, losing the opportunity to stay informed and involved in decisions that affect your community."
                )}
              </p>
            </InfoItem>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "You'll miss out on future relevant Commonplaces in your local area, limiting your opportunities to participate in initiatives that matter to you."
                )}
              </p>
            </InfoItem>
          </InfoItemContainer>
        </InfoBlock>
      </ContentContainer>
    </Wrapper>
  );
};
