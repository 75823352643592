import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { SPECIAL_CATEGORY_DATA } from 'Constants/demographics';
import { useDemographicsContext, useProject } from 'Client/utils/hooks';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { DemographicsClass } from 'Shared/classes/Demographics/Demographics';
import { SupportedLanguages } from 'Client/constants/languages';
import {
  QuestionsContainer,
  MeetTheTeamPanel,
  SpecialCategoryQuestionsContainer,
  DemographicsHeader,
} from './components';
import { DemographicsPageProps } from './types';
import {
  IntroDescription,
  FooterTextWrapper,
  DemographicsHeaderSection,
  HeaderTextContent,
  HeaderImageContainer,
  WrapperCf3,
} from './DemographicsPage.styles';
import LightBulb from './components/LightBulb';
import { insertProjectNames } from './utils/insertProjectsNames';

interface DemographicsPagePropsCf3 extends DemographicsPageProps {
  demographics: DemographicsClass;
}
export const DemographicsPageCf3: React.FC<DemographicsPagePropsCf3> = ({
  header,
  subheader,
  lightBulb,
  questions,
  stakeholders,
  teamSectionIntro,
  teamSectionFooter,
  specialCategoryTitle,
  specialCategoryDescription,
  footerText,
  demographics,
}) => {
  const { i18n } = useTranslation();
  const project = useProject();
  const { demographicsAnswers } = useDemographicsContext();

  React.useEffect(() => {
    (async () => {
      if (!demographics) return;
      await demographics.assignAnswers(
        demographicsAnswers,
        i18n.language as SupportedLanguages
      );
    })();
  }, [demographicsAnswers]);

  const nonSpecialQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType !== SPECIAL_CATEGORY_DATA
  );
  const specialCategoryQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType === SPECIAL_CATEGORY_DATA
  );

  const treatedSubheader = insertProjectNames(subheader, project.name);
  const treatedTeamSectionIntro = insertProjectNames(
    teamSectionIntro,
    project.name
  );
  const treatedTeamSectionFooter = insertProjectNames(
    teamSectionFooter,
    project.name
  );

  const treatedSpecialCategoryDescription = insertProjectNames(
    specialCategoryDescription,
    project.name
  );

  return (
    <>
      <DemographicsHeaderSection>
        <HeaderTextContent>
          <DemographicsHeader>{header}</DemographicsHeader>
          <IntroDescription>{treatedSubheader}</IntroDescription>
        </HeaderTextContent>
        <HeaderImageContainer>
          <img
            src="/static/illustrations/demographics_header.svg"
            width="auto"
            height="auto"
          />
        </HeaderImageContainer>
      </DemographicsHeaderSection>
      <WrapperCf3>
        <LightBulb>{lightBulb}</LightBulb>
        <QuestionsContainer questions={nonSpecialQuestions} />
        {specialCategoryQuestions && specialCategoryQuestions.length > 0 && (
          <SpecialCategoryQuestionsContainer
            title={specialCategoryTitle}
            description={treatedSpecialCategoryDescription}
            questions={specialCategoryQuestions}
          />
        )}
        <MeetTheTeamPanel
          intro={treatedTeamSectionIntro}
          footer={treatedTeamSectionFooter}
          stakeholders={stakeholders}
        />
        {footerText && (
          <FooterTextWrapper>
            <Typography>{footerText}</Typography>
          </FooterTextWrapper>
        )}
        <EditModeButton />
      </WrapperCf3>
    </>
  );
};
