import { gql } from '@apollo/client';
import { PseudoDemographics } from 'Shared/types/demographics';
import getClient from 'Shared/utils/apollo-client';

export const UPDATE_PSEUDO_DEMOGRAPHICS = gql`
  mutation UpdatePseudoDemographics(
    $pseudoDemographicsId: String
    $updates: JSON
  ) {
    updatePseudoDemographics(
      pseudoDemographicsId: $pseudoDemographicsId
      updates: $updates
    )
  }
`;

export const updatePseudoDemographics = async (
  pseudoDemographicsId: string,
  updates: Partial<PseudoDemographics>,
  apiToken: string
) => {
  const client = getClient(apiToken);
  const {
    data: { updatePseudoDemographics },
  } = await client.mutate({
    mutation: UPDATE_PSEUDO_DEMOGRAPHICS,
    variables: {
      pseudoDemographicsId: pseudoDemographicsId,
      updates: updates,
    },
  });
  return updatePseudoDemographics;
};
