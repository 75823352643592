import { Contribution } from 'Shared/types/contribution';

export const getPivotValue = (
  pivotQuestion: string,
  contribution: Contribution
) => {
  const pivotValue =
    pivotQuestion &&
    contribution.answersPopulated.find(
      (answer) => answer.questionId.toString() === pivotQuestion
    ).value;

  const pivot =
    pivotValue &&
    (Array.isArray(pivotValue)
      ? pivotValue[0].toString()
      : pivotValue.toString());

  return pivot;
};
