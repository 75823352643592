import { gql } from '@apollo/client';
import { DemographicsAcorn } from 'Shared/types/demographics';
import getClient from 'Shared/utils/apollo-client';

export const CREATE_ACORN_DEMOGRAPHICS = gql`
  mutation CreateDemographicsAcorn($demographics: JSON) {
    createDemographicsAcorn(demographics: $demographics)
  }
`;

export const createAcornDemographics = async (
  demographics: DemographicsAcorn,
  apiToken: string
) => {
  const client = getClient(apiToken);
  const {
    data: { createDemographicsAcorn },
  } = await client.mutate({
    mutation: CREATE_ACORN_DEMOGRAPHICS,
    variables: {
      demographics: demographics,
    },
  });
  return createDemographicsAcorn;
};
