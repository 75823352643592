import { gql } from '@apollo/client';
import { Contribution } from 'Shared/types/contribution';
import getClient from 'Shared/utils/apollo-client';

export const CREATE_COMMENT_CONTRIBUTION = gql`
  mutation CreateCommentContribution($createCommentContributionInput: JSON) {
    createCommentContribution(
      createCommentContributionInput: $createCommentContributionInput
    ) {
      data
      message
      success
    }
  }
`;

export const createCommentContribution = async (
  contribution: Contribution<'comment'>,
  apiToken: string
) => {
  const client = getClient(apiToken);
  const { data } = await client.mutate({
    mutation: CREATE_COMMENT_CONTRIBUTION,
    variables: {
      createCommentContributionInput: contribution,
    },
  });
  return data;
};
