import { gql } from '@apollo/client';
import { PseudoDemographics } from 'Shared/types/demographics';
import getClient from 'Shared/utils/apollo-client';

export const CREATE_PSEUDO_DEMOGRAPHICS = gql`
  mutation CreatePseudoDemographics(
    $demographicsId: String
    $pseudoDemographics: JSON
  ) {
    createPseudoDemographics(
      demographicsId: $demographicsId
      pseudoDemographics: $pseudoDemographics
    )
  }
`;

export const createPseudoDemographics = async (
  demographicsId: string,
  pseudoDemographics: PseudoDemographics,
  apiToken: string
) => {
  const client = getClient(apiToken);
  const {
    data: { createPseudoDemographics },
  } = await client.mutate({
    mutation: CREATE_PSEUDO_DEMOGRAPHICS,
    variables: {
      demographicsId: demographicsId,
      pseudoDemographics: pseudoDemographics,
    },
  });
  return createPseudoDemographics;
};
