import { gql } from '@apollo/client';
import { ObjectId } from 'mongodb';
import { Consent } from 'Shared/types/consent';
import getClient from 'Shared/utils/apollo-client';

export const UPDATE_CONSENT = gql`
  mutation UpdateConsent($consentId: String!, $updates: JSON) {
    updateConsent(consentId: $consentId, updates: $updates)
  }
`;

export const updateConsentGql = async (
  consentId: string | ObjectId,
  updates: Partial<Consent>,
  apiToken: string
) => {
  const client = getClient(apiToken);

  const {
    data: { updateConsent },
  } = await client.mutate({
    mutation: UPDATE_CONSENT,
    variables: {
      consentId,
      updates,
    },
  });
  return updateConsent;
};
