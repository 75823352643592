import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDemographicsContext } from 'Client/utils/hooks';
import { OptionItem } from 'Client/types';
import { QuestionTypes } from 'Shared/types/question';
import { DemographicsQuestionRenderer } from '.';
import { DemographicsQuestion } from '../types';

interface EventData {
  event?:
    | OptionItem
    | React.ChangeEvent<{ value: string }>
    | Array<{ content: OptionItem }>;
  questionId: string;
  question: DemographicsQuestion;
  type: QuestionTypes;
}

export const DemographicsQuestions: React.FC<{
  questions?: DemographicsQuestion[];
}> = ({ questions }) => {
  const { t } = useTranslation();
  const { dispatchDemographics } = useDemographicsContext();
  const handleQuestionUpdate = (data: EventData) => {
    const { event, type } = data;
    let questionAnswer;
    if (!event) {
      // when value is cleared
      questionAnswer = '';
    } else if (event && type === 'priorities') {
      questionAnswer = (event as Array<{ content: OptionItem }>).map((item) => {
        return item.content.value;
      });
    } else if (event && (event as OptionItem)?.value) {
      questionAnswer = (event as OptionItem)?.value;
    } else if (Array.isArray(event)) {
      questionAnswer = event;
    } else {
      // failsafe
      const { value, id } = (
        event as React.ChangeEvent<{ value: string; id: string }>
      ).target;
      let _value = value;
      if (id === 'postcode-field') {
        _value = value.replace(/\s/g, '') || value;
      }
      questionAnswer = _value;
    }

    const newData = {
      ...data,
      questionAnswer,
    };
    console.log(
      '🚀 ~ handleQuestionUpdate ~ questionAnswer:',
      questionAnswer,
      newData
    );
    // onChange(newData)

    dispatchDemographics({ type: 'UPDATE_ANSWER', payload: newData });
  };

  return (
    <>
      {questions
        ?.sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((q) => {
          if (q.content) {
            const { category, explanation, ...restContent } = q.content;
            return (
              <DemographicsQuestionRenderer
                key={q.questionId}
                data-testid={'DemographicsQuestionRenderer'}
                category={category}
                type={restContent.type}
                infoTip={explanation}
                question={{ id: q.questionId, ...restContent }}
                handleChange={(e) =>
                  handleQuestionUpdate({
                    event: e,
                    questionId: q.questionId,
                    question: q,
                    type: restContent.type,
                  })
                }
              />
            );
          }
          return (
            <span key={q.questionId} data-questionid={q.questionId}>
              <i>{t('Question content could not be fetched.')}</i>
            </span>
          );
        })}
    </>
  );
};
