import * as React from 'react';
import { captureException } from '@sentry/node';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'react-i18next';
import {
  MixpanelEventTypes,
  useAnalytics,
  useProject,
} from 'Client/utils/hooks';
// import { EngagementPagesTemplate } from 'Templates/EngagementPages';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { ProjectConsentsClass } from 'Shared/classes/Consents/Consents';
import { titleCase } from 'Client/utils/stringManipulations';
import { PreferencesPageProps, Consent } from './types';
import { ConsentsWrapper, Header, WrapperCf3 } from './PreferencesPage.styles';
import { ConsentsOption } from './components/ConsentsOption';
// import { cpBrandNamePlural } from 'Client/constants/brand';
// import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { AllDefaultConsents } from './components';

interface PreferencesPagePropsCf3 extends PreferencesPageProps {
  projectConsents: ProjectConsentsClass;
}
export const PreferencesPageCf3: React.FC<PreferencesPagePropsCf3> = ({
  defaultConsents,
  customConsents,
  projectConsents,
}) => {
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const { trackEvent } = useAnalytics();
  const project = useProject();
  const titleCasedProjectName = titleCase(project.name);
  const customerName = project.customer || t('the customer');
  const [consents, setConsents] = React.useState<Array<Consent>>([]);
  const unselectedDefaultConsents = defaultConsents?.consents.filter(
    (c) =>
      !projectConsents?.consents.find(
        (pc) => pc.type === c.type && pc.heading === c.heading
      )?.enabled
  );
  const unselectedCustomConsents = customConsents?.consents.filter(
    (c) =>
      !projectConsents?.consents.find(
        (pc) => pc.type === c.type && pc.heading === c.heading
      )?.enabled
  );

  const toggleConsents = async (consent: Consent): Promise<void> => {
    try {
      const email = projectConsents?.dataHandlers?.user?.email;
      const { enabled } = consent;
      const existingConsent = projectConsents.consents.find(
        (c) => c.type === consent.type && c.heading === consent.heading
      );
      if (!existingConsent) {
        // check correctly if consent already exists for user & project not only type?
        await projectConsents.createConsent(consent);
        if (consent.type === CONSENT_TYPES.PROJECT_NEWS) {
          trackEvent(MixpanelEventTypes.NEWS_SUBSCRIPTION, {
            path: asPath,
            email,
          });
        }
        if (consent.type === CONSENT_TYPES.NEW_COMMONPLACES) {
          trackEvent(MixpanelEventTypes.NEW_COMMONPLACES_SUBSCRIPTION, {
            path: asPath,
            email,
          });
        }
      } else {
        await projectConsents.updateConsent(existingConsent, {
          enabled: consent.enabled,
        });
      }
      if (enabled) {
        const newConsents = [...consents, consent];
        setConsents(newConsents);
        return;
      }
      const newConsents = consents.filter((c) => c.heading !== consent.heading);
      setConsents(newConsents);
    } catch (e) {
      captureException(e);
    }
  };
  React.useEffect(() => {
    if (!consents.length) return;
    trackEvent(MixpanelEventTypes.ADDED_PREFERENCES, {
      path: asPath,
      consents: consents?.map((c) => ({ type: c.type, enabled: c.enabled })),
    });
  }, [consents]);

  const showDefaultConsents = unselectedDefaultConsents?.length > 0;
  const showCustomConsents =
    unselectedCustomConsents?.length > 0 &&
    customConsents &&
    (customConsents?.consents?.length ?? 0) > 0;
  const orderedCustomConsents = [...customConsents.consents].sort(
    (a, b) => a.order - b.order
  );

  const handleDefaultConsentsToggle = async (checked: boolean) => {
    // const { enabled: checked } = consent;
    const userConsents = await projectConsents.checkExistentConsents();
    defaultConsents.consents.map(async (consent) => {
      const existingConsent = userConsents.find((c) => c.type === consent.type);
      if (!existingConsent && checked) {
        await projectConsents.createConsent({ ...consent, enabled: checked });
        return { ...consent, enabled: checked };
      } else {
        await projectConsents.updateConsent(existingConsent, {
          enabled: checked,
        });
        return { ...existingConsent, enabled: checked };
      }
    });
  };
  return (
    <WrapperCf3>
      <Header>
        <img src="/static/illustrations/megaphone-cellphone.png" width="200" />{' '}
        <div>
          <h1>{t('One last thing...')}</h1>
          <div>
            <p>
              <strong>{t('Are you sure?')} 😮</strong>
            </p>
            <Trans>
              <p>
                You&apos;ll miss out on updates from {customerName} about{' '}
                {titleCasedProjectName} with your current settings.
              </p>
            </Trans>
          </div>
        </div>
      </Header>

      <ConsentsWrapper>
        {showDefaultConsents && (
          <AllDefaultConsents handleToggle={handleDefaultConsentsToggle} />
        )}
        {showCustomConsents && (
          <>
            {orderedCustomConsents.map((c) => (
              <ConsentsOption
                key={c.order}
                {...c}
                onConsentsToggle={toggleConsents}
              />
            ))}
          </>
        )}
      </ConsentsWrapper>
      <EditModeButton />
    </WrapperCf3>
  );
};
