import * as React from 'react';
import { useRouter } from 'next/router';
import fetch from 'isomorphic-unfetch';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { captureException } from '@sentry/node';
import {
  Typography,
  FormContainer,
  InitI18n,
  LoadingButtonStates,
} from 'Atoms';
import { EmailConfirmationIcon } from 'Icons';
import { TextField } from 'Molecules';
import {
  parseSyntaxValidationResult,
  validateEmail,
} from 'Client/utils/validators';
import {
  useProject,
  useAnalytics,
  MixpanelEventTypes,
  useMap,
  useProposalContext,
} from 'Client/utils/hooks';
import { handleEmailSubmit } from 'Client/services/proposals';
import { syntaxValidationRequest } from 'Client/services/validation';
import { sendConfirmationEmail } from 'Client/services/email';
import { fetchUserLanguage, getBlockedData } from 'Client/services/user';
import { getInitEmailInput } from 'Client/utils/contributionFlow';
import {
  getLocalItem,
  setLocalItem,
  CONFIRMATION_EMAIL_SENT_ITEM,
  CONTRIBUTION_SESSION_ITEM,
} from 'Client/utils/localstorage';
import useTrackEmailValidation from 'Client/utils/hooks/useAnalytics/useTrackEmailValidation';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import LoadingScreen from 'Client/components/molecules/LoadingScreen/LoadingScreen';
import { SupportedLanguages } from 'Client/constants/languages';
import { CommentContribution } from 'Shared/classes/Contribution/Contribution';
import { InfoPanel } from './components';
import {
  ContentCf3,
  EmailPageContainerCf3,
  Header,
  NextButton as ResendEmailButton,
} from '../email/EmailPage.styles';
import { ConfirmEmailPageProps } from './types';
import { PrivacyPolicyNotice, BottomPanel } from '../email/components';
import { ResendMessage } from '../email/components/ResendMessage';

interface ConfirmEmailPagePropsCf3 extends ConfirmEmailPageProps {
  contribution: CommentContribution;
}
export const ConfirmEmailPageCf3: React.FC<ConfirmEmailPagePropsCf3> = ({
  title,
  info,
  hint,
  action,
  contribution,
}) => {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const project = useProject();
  const { trackEvent } = useAnalytics();
  const [
    { contributionId, contributionType, signupEmail, customTitle },
    dispatch,
  ] = useProposalContext();
  const {
    state: { contributionFlow: mapContributionFlow },
  } = useMap();
  const [emailInputValue, setEmailInputValue] = React.useState('');

  React.useEffect(() => {
    if (contribution?.dataHandlers?.user?.email === emailInputValue) return;
    setEmailInputValue(contribution?.dataHandlers?.user?.email);
  }, [contribution?.dataHandlers?.user?.email]);

  const [emailSuccess, setEmailSuccess] = React.useState(false);
  const [emailValidationStatus, setEmailValidationStatus] =
    React.useState(null);
  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const localUserId = getLocalItem(CONTRIBUTION_SESSION_ITEM);

  React.useEffect(() => {
    const initEmailInput = async () => {
      const existingEmail = await getInitEmailInput(localUserId, signupEmail);
      if (existingEmail && existingEmail !== 'undefined') {
        setEmailInputValue(existingEmail.toLowerCase());
      }
    };

    if (emailInputValue === '' && (signupEmail || localUserId)) {
      initEmailInput();
    }
  }, [emailInputValue, localUserId, signupEmail]);

  const handleEmailInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSubmitWithForce(false);
    setEmailValidationStatus(null);
    setEmailInputValue(e.target.value?.toLowerCase());
  };

  const userEmailUpdated = async () => {
    try {
      await fetch('/api/demographics/email', {
        method: 'PATCH',
        body: JSON.stringify({
          newEmail: emailInputValue,
          previousEmail: signupEmail,
        }),
      });
    } catch (error) {
      captureException(
        `error in userEmailUpdated @ ConfirmEmailPage.tsx : ${error}`
      );
    }
  };

  const trackEmailValidation = useTrackEmailValidation();
  const handleResendEmailClick = async () => {
    if (!emailIsAlreadyValidated()) {
      // Check against the basic email validation regex
      if (!validateEmail(emailInputValue)) {
        return setEmailValidationStatus({
          type: 'error',
          message: t('This does not appear to be a valid email address'),
        });
      }

      setIsSubmitting(true);

      // Unless user wants to force submit in spite of previous warning we call
      // external validation api and handle the result
      const externalValidationStatus = submitWithForce
        ? null
        : await syntaxValidationRequest({
            data: emailInputValue,
          }).then(parseSyntaxValidationResult(t));

      trackEmailValidation(emailInputValue, externalValidationStatus, {
        contributionId,
      });

      // If there is a result from the validation api call, set the status message
      // and allow user to force submit if type is not 'error'
      if (externalValidationStatus) {
        setSubmitWithForce(externalValidationStatus.type === 'warning');
        setIsSubmitting(false);
        return setEmailValidationStatus(externalValidationStatus);
      }
    }

    // gaming check
    const { blocked } = await getBlockedData({
      email: emailInputValue,
      id: null,
    });

    if (blocked) {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
        isOpen: true,
      });
      setIsSubmitting(false);
      return;
    }

    setSubmitWithForce(false);
    setEmailValidationStatus(null);
    setEmailInputValue(emailInputValue.toLowerCase());
    try {
      const contrId = mapContributionFlow
        ? mapContributionFlow.contributionId
        : contributionId;
      const contrType = mapContributionFlow
        ? mapContributionFlow.type
        : contributionType;

      const localStorageUserId = await handleEmailSubmit({
        emailValue: emailInputValue.toLowerCase(),
        language: i18n.language as SupportedLanguages,
        contributionId: contrId,
        project,
      });

      trackEvent(MixpanelEventTypes.RESENT_EMAIL, {
        path: router.asPath,
      });

      const userLang = localStorageUserId
        ? await fetchUserLanguage(localStorageUserId)
        : i18n.language;

      const res = await sendConfirmationEmail({
        email: emailInputValue,
        contributionId: contrId,
        contributionType: contrType,
        lang: userLang,
      });
      if (res && res?.user && res?.user?._id) {
        setLocalItem(CONFIRMATION_EMAIL_SENT_ITEM, res.user._id);
      }
      await userEmailUpdated();
      setEmailSuccess(true);
    } catch (error) {
      captureException(
        `error in handleResendEmailClick @ ConfirmEmailPage.tsx : ${error}`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // If the email has been saved then it has already passed validation - as long
  // as saved email matches email input current value we can be confident...
  const emailIsAlreadyValidated: () => boolean = () => {
    const data = mapContributionFlow
      ? mapContributionFlow.data.userEmail
      : signupEmail;

    return data && emailInputValue === data;
  };

  const generateTitle = (): string => {
    if (Object.keys(customTitle)?.length < 1) return title;

    let newTitle = title;

    Object.keys(customTitle).forEach((key) => {
      newTitle = newTitle.replace(`{{${key}}}`, `${customTitle[key]}`);
    });

    return newTitle;
  };

  return (
    <EmailPageContainerCf3>
      <InitI18n />
      <ContentCf3>
        <FormContainer>
          <Header data-testid="ConfirmEmailPage-Header">
            {generateTitle()}
          </Header>
          <InfoPanel message={info} email={signupEmail} />
          <Box mt={0}>
            <EmailConfirmationIcon />
          </Box>
          {isSubmitting && <LoadingScreen withLoadingRing={true} />}

          {!emailSuccess ? (
            <>
              <Box mt={2}>
                <Typography variant="h3" gutterBottom>
                  {hint}
                </Typography>
                <Typography variant="body2">{action}</Typography>
              </Box>
              <Box mt={1} textAlign="left">
                <TextField
                  id="email"
                  placeholder={t('e.g. sam@smith.com')}
                  label={t('E-mail')}
                  width="100%"
                  type="email"
                  status={emailValidationStatus}
                  value={emailInputValue}
                  handleChange={handleEmailInputChange}
                />
                <PrivacyPolicyNotice />
                <ResendEmailButton
                  onClick={handleResendEmailClick}
                  disabled={isSubmitting}
                  resetState={() => {}}
                  state={
                    isSubmitting
                      ? LoadingButtonStates.LOADING
                      : LoadingButtonStates.INITIAL
                  }
                >
                  {t('Resend email')}
                </ResendEmailButton>
              </Box>
            </>
          ) : (
            <ResendMessage />
          )}
        </FormContainer>
        <BottomPanel />
      </ContentCf3>
    </EmailPageContainerCf3>
  );
};
