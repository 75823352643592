import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { LanguageSelector } from 'Organisms';
import { ResponsesCounter } from 'Organisms/ResponsesCounter';
import { StyledButton } from 'Client/components/molecules/StyledButton';
import { ProposalStage } from 'Client/pages/proposals/types';
import { useProject } from 'Client/utils/hooks';
import { MastheadProps } from './types';
import {
  Wrapper,
  Content,
  Title,
  Stage,
  HomeLink,
  HomeTitleContainer,
} from './Masthead.styles';

export const Masthead: React.FC<MastheadProps> = ({
  title,
  slug,
  stage,
  hideCommentsCount,
  printPage,
}: MastheadProps) => {
  const { t } = useTranslation();
  const project = useProject();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Wrapper data-testid="ProposalPageTemplate-Masthead">
      <Content>
        <HomeTitleContainer>
          <Link href="/" passHref>
            <HomeLink tabIndex={0} data-testid="ProposalMastheadBackHomeButton">
              <ArrowBackIcon fontSize="small" />
              {!isMobile && <p> {t('Home')}</p>}
            </HomeLink>
          </Link>
          {title && (
            <Title data-testid="Masthead-title">
              {title}
              {stage === ProposalStage.COMPLETED && (
                <Stage>[{t('completed')}]</Stage>
              )}
              {stage === ProposalStage.ARCHIVED && (
                <Stage>[{t('archived')}]</Stage>
              )}
            </Title>
          )}
        </HomeTitleContainer>
        {project?.features?.i18n && <LanguageSelector />}
        {printPage && (
          <StyledButton
            onClick={() => window.print()}
            data-testid="ProposalMastheadPrintPDFButton"
            color="purple"
          >
            {t('Print / Download PDF')}
          </StyledButton>
        )}
        {!hideCommentsCount && <ResponsesCounter slug={slug} />}
      </Content>
    </Wrapper>
  );
};
