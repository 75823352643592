import { gql } from '@apollo/client';

export const UPLOAD_FILE_TO_S3 = gql`
  mutation UploadToS3($file: Upload!) {
    uploadToS3(file: $file) {
      encoding
      filename
      mimetype
      url
    }
  }
`;

export const DELETE_DESCOPE_USER = gql`
  mutation DeleteDescopeUser($deleteDescopeUserInput: DeleteDescopeUserInput!) {
    deleteDescopeUser(deleteDescopeUserInput: $deleteDescopeUserInput)
  }
`;
