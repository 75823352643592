import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1100;
  background-color: ${({ theme }) => theme.colors.white[500]};
  box-shadow: 0.625rem 0 0.625rem ${({ theme }) => theme.colorMappings.shadow};
  display: flex;
  justify-content: center;

  @media print {
    display: none;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: ${theme.proposals.masthead.height};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94%;
    padding: ${theme.screenPadding};
  `};
`;

export const HomeTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HomeLink = styled.a`
  ${({ theme }) => css`
    padding-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3125rem;
    padding: 0 0.3125rem;
    .MuiSvgIcon-root {
      color: ${theme.colorMappings.hyperlink};

      ${theme.direction === 'rtl' &&
      css`
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
      `}
    }
    & > p {
      font-size: ${({ theme }) => theme.typography.button.fontSize};
      font-weight: ${({ theme }) => theme.typography.button.fontWeight};
      color: ${theme.colorMappings.hyperlink};
    }
    &:hover {
      .MuiSvgIcon-root,
      button {
        color: ${darken(0.1, theme.colorMappings.hyperlink)};
      }
    }

    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.1rem;
      outline-width: 0.1875rem;
    }
  `};
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? css`
          margin-right: 2.5rem;
        `
      : css`
          margin-left: 2.5rem;
        `}
`;

export const Stage = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colorMappings.success};
  ${({ theme }) =>
    theme.direction === 'ltr'
      ? css`
          margin-left: 0.5rem;
        `
      : css`
          margin-right: 0.5rem;
        `}
`;

export const ResponsesCounter = styled.div`
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.grey[200]};
  padding: 0.375rem 1rem;
  border-radius: 0.125rem;
  display: flex;

  > svg {
    align-self: center;
    padding-top: 0.3125rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin: 0 0.5rem;
      padding: 0.375rem 0.25rem;
    }
  `};
`;
