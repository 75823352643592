import { gql } from '@apollo/client';
import getClient from 'Shared/utils/apollo-client';

export const GET_DEMOGRAPHICS_BY_USER_BY_PROJECT_GQL = gql`
  query Query($userId: String, $projectId: String) {
    getDemographicsByUserByProject(userId: $userId, projectId: $projectId)
  }
`;

export const getDemographicsByUserByProject = async (
  userId: string,
  projectId: string,
  apiToken: string
) => {
  const client = getClient(apiToken);

  const {
    data: { getDemographicsByUserByProject: demographics },
  } = await client.query({
    query: GET_DEMOGRAPHICS_BY_USER_BY_PROJECT_GQL,
    variables: {
      userId: userId,
      projectId: projectId,
    },
  });
  return demographics;
};
