import * as React from 'react';
import { Trans } from 'react-i18next';
import { IconRefresh } from '@tabler/icons-react';
import { CheckboxChecked, CheckboxUnchecked } from 'Atoms/Icons';
import { cpBrandNamePlural } from 'Client/constants/brand';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { useProject } from 'Client/utils/hooks';
import { titleCase } from 'Client/utils/stringManipulations';
import {
  OptionCheckboxCf3,
  OptionContainer,
  RowOptionContent,
} from './ConsentsOption.styles';

export const AllDefaultConsents = ({ handleToggle }) => {
  const project = useProject();
  const titleCasedProjectName = titleCase(project.name);

  const [checked, setChecked] = React.useState<boolean>(false);
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [focused, setFocused] = React.useState<boolean>(false);

  const active = checked || hovered;
  return (
    <>
      <OptionContainer
        active={active}
        className={focused && 'hasFocus'}
        onClick={() => {
          const enabled = !checked;
          handleToggle(enabled);
          setChecked(enabled);
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        data-testid="consents-option-container"
      >
        <OptionCheckboxCf3
          active={active}
          checked={checked}
          icon={<CheckboxUnchecked />}
          checkedIcon={<CheckboxChecked />}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <RowOptionContent>
          <IconRefresh stroke={0.8} width={20} height={20} />
          <Trans>
            <p>
              Receive updates and replies about{' '}
              <span>{titleCasedProjectName}</span> and hear about{' '}
              {cpBrandNamePlural} in your area.
              <br /> We’ll never publish your personal data.
              <br /> See our{' '}
              <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                privacy policy
              </a>{' '}
              and{' '}
              <a href="/teams" target="_blank" rel="noreferrer">
                organisations involved
              </a>
              .
            </p>
          </Trans>
        </RowOptionContent>
      </OptionContainer>
    </>
  );
};
