import * as React from 'react';
import { Container, ProgressBar } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import { /*NavigationalMap,*/ QuestionRenderer } from 'Organisms';
import { DynamicProposals } from 'Client/pages/hub/components';
import {
  useEditModeContext,
  useProject,
  useProposalContext,
} from 'Client/utils/hooks';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { ImageComparison } from 'Client/components/organisms/ImageComparison';
import { ImageMapPro } from 'Client/components/molecules/ImageMapPro/ImageMapPro';
import { SectionProps } from '../';
import { calculateMinToRead } from '../utils';
import {
  FullWidthContainer,
  NoOverflowFullWidthContainer,
  ImageSectionTitle,
  ImageTextWrapper,
  ImageWrapper,
  Title,
  QuestionWrapper,
  ContentPreviewWrapper,
  ContentWrapper,
  TitleWrapper,
  Wrapper,
} from './Section.styles';
import { MediaUploadVisualizer } from '.';
import ImageAndGridVisualizer from './ImageAndGridVisualizer';
import AccordionVisualizer from './Accordion/AccordionVisualizer';
import { TwoColumnsVisualizer } from './TwoColumns/TwoColumnsVisualizer';

const Section: React.FC<SectionProps> = ({
  title,
  description,
  question,
  value,
  voiceValue,
  onChange,
  onBlur,
  image,
  sections,
  showProgressBar,
  showMinutesLeft,
  hideQuestion,
  imageComparisonSlider,
  files,
  pagePreview,
  imageAndGrid,
  accordionContent,
  twoColumns,
  navMap,
  backgroundColour,
  imageMapPro,
}: SectionProps) => {
  // A section object can have multiple sections of section (eg text-question-text-question),
  // or only questions (eg text-question-question-question) etc...
  const [{ currentStep, totalSteps }] = useProposalContext();
  const [state] = useEditModeContext();
  const [isEditModeLayout] = useEditModeLayout();
  const minToRead = calculateMinToRead(sections || [], currentStep);
  const hasDescription = description || description === '';
  const previewMode = state?.editModeLayout?.previewMode;
  const editModeDesktopZoomOut = true;
  const scaledDownLayout = previewMode === 'desktop' && editModeDesktopZoomOut;
  const project = useProject();
  const contributionFlow3Point0 = project?.features?.cf3 || false;

  return (
    <Wrapper backgroundColour={backgroundColour}>
      {(title || hasDescription) && !files && !pagePreview && (
        <>
          {image && image.type === 'banner' && (
            <ImageWrapper image={image.src}>
              <ImageTextWrapper>
                <ImageSectionTitle>{image.title}</ImageSectionTitle>
                <br />
                {image.subtitle && (
                  <ImageSectionTitle>{image.subtitle}</ImageSectionTitle>
                )}
              </ImageTextWrapper>
            </ImageWrapper>
          )}
          <Container backgroundColour={backgroundColour}>
            {showProgressBar && !contributionFlow3Point0 && (
              <ProgressBar
                showMinutesLeft={showMinutesLeft}
                currentStep={currentStep}
                totalSteps={totalSteps}
                minToRead={minToRead}
              />
            )}
            {title && (
              <TitleWrapper
                id="proposal-title"
                isEditModeLayout={isEditModeLayout}
                backgroundColour={backgroundColour}
              >
                <Title variant="h2">{title}</Title>
              </TitleWrapper>
            )}
          </Container>
          {image && image.type === 'container' && (
            <Container backgroundColour={backgroundColour}>
              <img
                src={image.src}
                alt={`alt ${image.alt || 'section image'}`}
                width="100%"
              />
            </Container>
          )}
          {hasDescription && (
            <Container
              data-testId="proposal-description-block"
              backgroundColour={backgroundColour}
            >
              <ContentWrapper
                id="proposal-description"
                data-disable-events
                isEditModeLayout={isEditModeLayout}
              >
                <HtmlDescription>{description}</HtmlDescription>
              </ContentWrapper>
            </Container>
          )}
        </>
      )}
      {imageComparisonSlider && (
        <ImageComparison
          backgroundColour={backgroundColour}
          imageComparisonSlider={imageComparisonSlider}
        />
      )}
      {imageAndGrid && (
        <NoOverflowFullWidthContainer
          backgroundColour={backgroundColour}
          scaledDownLayout={scaledDownLayout}
        >
          <ImageAndGridVisualizer
            files={imageAndGrid.files}
            layout={imageAndGrid.layout}
            alignment={imageAndGrid.alignment}
          />
        </NoOverflowFullWidthContainer>
      )}
      {files && (
        <FullWidthContainer
          scaledDownLayout={scaledDownLayout}
          backgroundColour={backgroundColour}
        >
          <MediaUploadVisualizer files={files} title={title} />
        </FullWidthContainer>
      )}
      {question && !hideQuestion && (
        <FullWidthContainer
          scaledDownLayout={scaledDownLayout}
          backgroundColour={backgroundColour}
        >
          <QuestionWrapper
            id="proposal-question"
            data-testid="proposal-question"
            data-disable-events
          >
            <QuestionRenderer
              question={question}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              voiceValue={voiceValue}
            />
          </QuestionWrapper>
        </FullWidthContainer>
      )}
      {pagePreview && (
        <FullWidthContainer
          scaledDownLayout={scaledDownLayout}
          backgroundColour={backgroundColour}
        >
          <ContentPreviewWrapper>
            <DynamicProposals content={pagePreview} />
          </ContentPreviewWrapper>
        </FullWidthContainer>
      )}
      {accordionContent && (
        <NoOverflowFullWidthContainer backgroundColour={backgroundColour}>
          <AccordionVisualizer accordionContent={accordionContent} />
        </NoOverflowFullWidthContainer>
      )}
      {twoColumns && (
        <FullWidthContainer
          scaledDownLayout={scaledDownLayout}
          whiteBackground
          backgroundColour={backgroundColour}
        >
          <TwoColumnsVisualizer
            left={twoColumns.left}
            right={twoColumns.right}
          />
        </FullWidthContainer>
      )}
      {navMap && (
        <FullWidthContainer
          scaledDownLayout={scaledDownLayout}
          backgroundColour={backgroundColour}
        >
          <ContentPreviewWrapper>
            <div>{/* <NavigationalMap content={navMap} /> */}</div>
          </ContentPreviewWrapper>
        </FullWidthContainer>
      )}
      {imageMapPro && (
        <FullWidthContainer
          scaledDownLayout={scaledDownLayout}
          backgroundColour={backgroundColour}
        >
          <ImageMapPro content={imageMapPro} isEditMode />
        </FullWidthContainer>
      )}
    </Wrapper>
  );
};

export { Section };
