import * as React from 'react';
// import { captureException } from '@sentry/node';
import { useRouter } from 'next/router';
// import fetch from 'isomorphic-unfetch';
// import Head from 'next/head';
import { useTranslation } from 'react-i18next';
// import { useMutation } from '@apollo/client';
// import { UPLOAD_FILE_TO_S3 } from 'Client/utils/gql/mutations.gql';
// import { SeoMetaInfo } from 'Atoms';
// import { ProposalPagesTemplate as Template } from 'Templates';
import { useDebounce } from 'use-debounce';
import Draw from 'ol/interaction/Draw';
import {
  useMap,
  // useDemographicsContext,
  useProposalContext,
  useUser,
  // useProject,
  // useAnalytics,
  // MixpanelEventTypes,
  // usePermissions,
} from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
// import { saveCommentFirstTime } from 'Client/services/contributions';
// import { handleSubmitComment } from 'Client/services/contributionFlow';
// import { checkOnUserLanguage } from 'Client/services/user';
// import { EditModeButton } from 'Pages/edit/components/EditModeButton';
// import { ContributionType } from 'Shared/types/contribution';
// import {
//   getLocalItem,
//   setLocalItem,
//   CONFIRMATION_EMAIL_SENT_ITEM,
//   CONTRIBUTION_SESSION_ITEM,
// } from 'Client/utils/localstorage';
// import { Permissions } from 'Client/constants/permissions';
// import { PageTypes } from 'Shared/types/page';
import { canUserSeeDrafts } from 'Client/services/proposals';
import { CommentContribution } from 'Shared/classes/Contribution/Contribution';
// import { addNewQueryParam } from 'Client/utils/url';
import { DemographicsClass } from 'Shared/classes/Demographics/Demographics';
import { SupportedLanguages } from 'Client/constants/languages';
import { ProjectConsentsClass } from 'Shared/classes/Consents/Consents';
import { Contribution, ContributionStatus } from 'Shared/types/contribution';
// import { DemographicsStatus } from 'Shared/types/demographics';
import { NewProgressBarCf3 } from 'Atoms/NewProgressBarCf3/ProgressBar';
import { syntaxValidationRequest } from 'Client/services/validation';
import {
  validateEmail as validateEmailRegex,
  parseSyntaxValidationResult,
} from 'Client/utils/validators';
import { Masthead } from 'Client/templates/ProposalPages/components/Masthead';
import { Page as PageType } from 'Shared/types/page';
import { MapPageContent } from 'Shared/types/proposalPage';
import {
  ProposalPageProps,
  QuestionValue,
  // BlockContentStages,
  ProposalStage,
  EmptyAnswersPageProps,
  // ProposalSection,
  // Question,
} from './types';
import { NoPermissionPage } from '../noPermission';
import { SectionWrapperCf3 } from './components/SectionWrapperCf3';
import { DemographicsPageProps } from '../demographics';
import { EmailPageProps } from '../email/EmailPage';
import { PreferencesPageProps } from '../preferences';
import { ConfirmEmailPageProps } from '../confirmEmail';
import { NoHeaderEmptyAnswersPage } from './NoHeaderEmptyAnswers';
import { EmailPageCf3 } from '../email/EmailPageCf3';
import { DemographicsPageCf3 } from '../demographics/DemographicsPageCf3';
import { PreferencesPageCf3 } from '../preferences/PreferencesPageCf3';
import { ConfirmEmailPageCf3 } from '../confirmEmail/ConfirmEmailPageCf3';
import { ContributionFlowSectionsCf3 } from '../proposal/ContributionFlowSections';
import { EmailBlock } from './components/EmailBlock';
import { ProposalPageContainer } from './ProposalPageCf3.styles';
import { NavigationButtons } from './components/NavigationButtons';

/**
 * Wraps a component to render `NoPermissionPage` if the proposal is in draft stage
 * and the user doesn't have permission to view drafts.
 *
 * @todo Move this to its own file. Due to an import cycle in the login page,
 * `withDraftProtection` gets called before initialisation if it's located on its own
 * file. Once all imports of files in `/pages` have been fixed it should be safe to
 * move this.
 *
 * @param C - The component to wrap.
 * @returns The wrapped component.
 */
export const withDraftProtection = <P extends { stage?: ProposalStage }>(
  C: React.FC<P>
): React.FC<P> =>
  function ComponentWithPermission(props: P) {
    const { user } = useUser();

    const isDraft = props.stage === ProposalStage.DRAFT;
    const allow = !isDraft || canUserSeeDrafts(user);

    return allow ? <C {...props} /> : <NoPermissionPage />;
  };

export interface ProposalPagePropsV3 extends ProposalPageProps {
  demographicsPage: DemographicsPageProps;
  emailPage?: EmailPageProps;
  preferencesPage: PreferencesPageProps;
  confirmEmailPage: ConfirmEmailPageProps;
  emptyAnswersPage: EmptyAnswersPageProps;
  apiToken: string;
  mapPage?: PageType<MapPageContent>;
  isMap?: boolean;
  sentimentQuestionId?: string;
}
export const ProposalPageCf3: React.FC<ProposalPagePropsV3> =
  withDraftProtection(
    ({
      proposalId,
      proposalSlug,
      steps,
      showMinutesLeft,
      project,
      demographicsPage,
      emailPage,
      preferencesPage,
      confirmEmailPage,
      emptyAnswersPage,
      apiToken,
      isMap = false,
      sentimentQuestionId = '0',
      mapPage,
      // proposalTitle,
      // stage,
      // questions,
      // otherProposals,
      // contributionsNumber,
      prefillAnswersFromContribution,
      // numOfProposalSteps,
      // ...props
    }) => {
      const router = useRouter();
      const { user } = useUser();
      const { i18n, t } = useTranslation();
      const lang = (i18n.language as SupportedLanguages) || 'en-GB';
      const [{ answers }, dispatch] = useProposalContext();
      const useMapHook = useMap();
      const pageData = {
        _id: proposalId,
        slug: proposalSlug,
      };
      const [emailInput, setEmailInput] = React.useState<string>(null);
      const [emailValid, setEmailValid] = React.useState(true);
      const [userHasAllDefaultConsents, setUserHasAllDefaultConsents] =
        React.useState(false);

      const [submitWithForce, setSubmitWithForce] = React.useState(false);
      const [emailValidationStatus, setEmailValidationStatus] =
        React.useState(null);
      const [actualStep, setActualStep] = React.useState<string>(
        (router.query.step as string) || 'step1'
      );

      const [contribution, setContribution] =
        React.useState<CommentContribution>(null); // will start from ssr?
      const [demographics, setDemographics] =
        React.useState<DemographicsClass>(null); // from ssr?
      const [projectConsents, setProjectConsents] =
        React.useState<ProjectConsentsClass>(null); // from ssr?
      console.log('🅾️contribution', contribution);
      console.log('🅾️demographics', demographics);
      console.log('🅾️projectConsents', projectConsents);

      const defaultConsents = preferencesPage?.defaultConsents?.consents;
      const customConsents = preferencesPage?.customConsents?.consents;
      const sortedSteps =
        steps?.slice().sort((a, b) => a.order - b.order) || [];
      const answerSteps = sortedSteps.map((_, index) => `step${index + 1}`);
      const CfOrder = [
        ...answerSteps,
        'email',
        'demographics',
        'preferences',
        'confirm-email',
        'thanks',
        'empty-answers',
      ];
      const currentStep = CfOrder.indexOf(actualStep);
      const showButtons = CfOrder.includes(actualStep);
      const showEmailBlock = actualStep === 'step1' && !user; // change later on id step identifiers (skip logic)
      const hideBackButton = () => {
        const isFirstStep = currentStep === 0;
        if (isFirstStep) return true;
        return false;
      };

      const hideNextButton = () => {
        if (actualStep === 'confirm-email') return true;
        return false;
      };
      const hideButtons = {
        back: hideBackButton(),
        next: hideNextButton(),
      };
      const disableNextButton = (): boolean => {
        if (submitWithForce) return false;
        if (!emailValid) return true;
        if (actualStep === 'email') {
          if (!contribution.userId) return true;
        }
        return false;
      };
      const disableBackButton = () => {
        return false;
      };
      const disableButtons = {
        next: disableNextButton(),
        back: disableBackButton(),
      };
      console.log('🚀 ~ showEmailBlock:', showEmailBlock);
      console.log('🚀 ~ actualStep:', actualStep);
      console.log('🚀 ~ showButtons:', showButtons);
      console.log('🚀 ~ hideButtons:', hideButtons);

      React.useEffect(() => {
        console.log('ProposalPageCf3 - actualStep: ', actualStep);
        console.log('ProposalPageCf3 - sortedSteps: ', sortedSteps);
        sortedSteps;
      }, []);

      React.useEffect(() => {
        if (isMap && contribution) {
          contribution.setLocation(useMapHook.state.contributionFlow.geometry);
        }
      }, [useMapHook.state.contributionFlow]);

      const instantiateContribution = async (
        existentContribution: Partial<Contribution<'comment'>>
      ) => {
        console.log('🚀 ~ existentContribution:', existentContribution);
        if (contribution) return;

        const ctb = new CommentContribution({
          surveySlug: proposalSlug,
          pageId: proposalId,
          project,
          language: lang,
          apiToken,
          map: isMap,
          // existentContribution,
        });
        console.log('ctb: ', ctb);
        if (existentContribution) {
          await ctb.initFromExistentContribution(existentContribution);
          console.log(ctb);
          setEmailInput(ctb.dataHandlers.user.email);
        }

        if (user || emailInput) {
          ctb.assignUser(user);
        }

        if (isMap) {
          ctb.setSentimentQuestionId(sentimentQuestionId);
          ctb.setLocation(useMapHook.state.contributionFlow.geometry);
        }

        setContribution(ctb);
      };

      const instantiateDemographics = async () => {
        if (demographics || !contribution || !contribution.userId) return;
        const dm = new DemographicsClass({
          project,
          user: contribution.dataHandlers.user,
          contributionClass: contribution,
          consents: contribution.dataHandlers.consents,
          apiToken,
        });
        await dm.checkExistentDemographics();
        setDemographics(dm);
        return dm;
      };

      const instantiatePreferences = async (_demographics) => {
        if (projectConsents || !contribution || !contribution.userId) return;

        const pc = new ProjectConsentsClass({
          user: contribution.dataHandlers.user,
          project,
          demographics: _demographics,
          contribution,
          page: pageData,
          apiToken,
          // existentConsents: contribution.dataHandlers.consents,
        });
        await pc.checkExistentConsents();
        setProjectConsents(pc);
      };

      React.useEffect(() => {
        (async () => {
          let contribution = null;
          if (router.query?.cid) {
            //     // initialize with existing answers if query id present
            contribution = await prefillAnswersFromContribution(
              router.query.cid as string
            );
          }
          console.log('existent contribution', contribution);
          await instantiateContribution(contribution);
        })();
      }, []);

      React.useEffect(() => {
        (async () => {
          console.log(
            'instantiate demographs & prefers?',
            !contribution?.dataHandlers?.user
          );
          if (!contribution?.dataHandlers?.user) return;
          const demographics = await instantiateDemographics();
          await instantiatePreferences(demographics);
        })();
      }, [contribution?.dataHandlers?.user]);

      React.useEffect(() => {
        if (!projectConsents) return;
        /* Check for consents */
        projectConsents?.checkExistentConsents().then((userConsents) => {
          setUserHasAllDefaultConsents(
            userConsents.length && userConsents.every((c) => !!c.enabled)
          );
        });
      }, [contribution?.dataHandlers?.user, projectConsents]);

      const [newAnswers, setNewAnswers] = React.useState(null);
      const [debouncedNewAnswers] = useDebounce(newAnswers, 1000);

      const handleChange = (questionId: string, answer: QuestionValue) => {
        console.log('🚀 ProposalPageCf3 ~ handleChange() params: ', {
          questionId,
          answer,
        });

        const answersExist = Object.keys(answers).find(
          (answer) => answers[answer] !== ''
        );

        if (!answersExist) {
          // trigger the first time a question is answered
          // trackEvent(MixpanelEventTypes.ADDING_COMMENT, {
          //   path: router.asPath,
          // });
        }

        const newAnswers = { ...answers };

        if (
          (Array.isArray(answer) && answer.length === 0) ||
          answer == null ||
          answer === ''
        ) {
          // not ideal, but the whole CF treats empty strings as defaults
          // in future we should move away from the expectation that all answers exist and start as strings
          newAnswers[questionId] = '';
        } else {
          newAnswers[questionId] = answer;
        }

        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
          answers: newAnswers,
        });

        setNewAnswers(newAnswers);
      };

      const updateStepsOnQuery = (step: string) => {
        console.log('🚀 ~ updateStepsOnQuery ~ step:', step);
        let url = `/proposals/v3/${proposalSlug}?step=${step}`;
        if (contribution?.utils?.dbEntryCreated) {
          url += `&cid=${contribution._id}`;
        }
        window.history.pushState({}, '', url);
      };

      React.useEffect(() => {
        if (debouncedNewAnswers) {
          console.log('debouncedNewAnswers: ', debouncedNewAnswers);

          contribution.assignAnswer(debouncedNewAnswers);
        }
      }, [debouncedNewAnswers]);

      const changeStep = async (
        direction: 'forwards' | 'backwards',
        step?: string
      ) => {
        console.log('🚀 ~ changeStep:', direction, 'actual:', actualStep);
        // console.log('ctb on change step', contribution);
        const shouldSkipEmailPage = !!user; // only skip if user is already logged in
        console.log('🚀 ~ shouldSkipEmailPage:', shouldSkipEmailPage);
        const shouldSkipDemographics =
          contribution?.demographicsId &&
          contribution?.dataHandlers?.demographics?.status === 'confirmed';
        console.log(
          'shouldSkipDemographics',
          shouldSkipDemographics,
          contribution?.demographicsId,
          !!contribution?.dataHandlers?.demographics
        );
        const shouldSkipPreferences = // to be actually selectedConsents !== total consents (only show unselected ones on page & some on email drawer)
          !customConsents.length && userHasAllDefaultConsents; // project only has default & user checked them all
        console.log(
          '🚀 ~ shouldSkipPreferences:',
          shouldSkipPreferences,
          !customConsents.length,
          '&& ',
          userHasAllDefaultConsents
        );
        const shouldSkipConfirmEmail = !!user; //not skiping email page when adding email on drawer
        console.log('🚀 ~ shouldSkipConfirmEmail:', shouldSkipConfirmEmail);
        console.log('contribution?.answers', contribution?.answers);
        const hasAnswers = contribution?.answers
          ? !!Object.keys(contribution?.answers).length
          : false;
        console.log('🚀 ~ hasAnswers:', hasAnswers);

        let newStepIndex = currentStep + (direction === 'forwards' ? 1 : -1);
        console.log('🚀 ~ newStepIndex:', newStepIndex);
        let newStep = CfOrder[newStepIndex];
        console.log('🚀 ~ newStep:', newStep);

        if (step) {
          console.log('yes step', step);
          updateStepsOnQuery(step);
          return setActualStep(step);
        }

        if (newStep === 'email' && !hasAnswers) {
          if (direction === 'forwards') {
            newStepIndex++;
            newStep = 'empty-answers';
          }
          if (direction === 'backwards') {
            newStepIndex--; //last step
            newStep = CfOrder[newStepIndex];
          }
        }
        console.log('newStep 0', newStep);

        if (newStep === 'email' && shouldSkipEmailPage) {
          if (direction === 'forwards') {
            newStepIndex++; //demographics
            contribution.updateContribution({
              draft: false,
              status: ContributionStatus.PENDING,
            });
            newStep = CfOrder[newStepIndex];
          }
          if (direction === 'backwards') {
            newStepIndex--; // last step
            newStep = CfOrder[newStepIndex];
          }
        }
        console.log('newStep', newStep, contribution.userId);
        if (
          !contribution.userId &&
          ['demographics', 'preferences'].includes(newStep)
        ) {
          // hard validation - cant go trough demographics and preferences without email/user
          newStep = 'email';
          updateStepsOnQuery(newStep);
          return setActualStep(newStep);
        }
        if (newStep === 'demographics' && shouldSkipDemographics) {
          if (direction === 'forwards') {
            newStepIndex++; // preferences
            newStep = CfOrder[newStepIndex];
          }
          if (direction === 'backwards') {
            newStepIndex--; // email
            if (shouldSkipEmailPage) newStepIndex--; // last step
            newStep = CfOrder[newStepIndex];
          }
        }
        if (newStep === 'preferences') {
          if (shouldSkipPreferences) {
            if (direction === 'forwards') {
              newStepIndex++; // confirm-email
              newStep = CfOrder[newStepIndex];
            }
            if (direction === 'backwards') {
              newStepIndex--; // demographics
              if (shouldSkipDemographics) newStepIndex--; // email
              if (shouldSkipEmailPage && shouldSkipDemographics) newStepIndex--; // last step

              newStep = CfOrder[newStepIndex];
            }
          }
        }
        if (newStep === 'confirm-email') {
          if (shouldSkipConfirmEmail) {
            if (direction === 'forwards') {
              newStepIndex++; // thanks
              newStep = CfOrder[newStepIndex];
            }
            if (direction === 'backwards') {
              newStepIndex--; // preferences
              if (shouldSkipPreferences) newStepIndex--; // demographics
              if (shouldSkipDemographics) newStepIndex--; // email
              if (shouldSkipEmailPage && shouldSkipDemographics) newStepIndex--; // last step
              newStep = CfOrder[newStepIndex];
            }
          }
        }
        if (newStep === 'thanks') {
          if (isMap) {
            const {
              state: { xyz, draftContributionLayer },
              dispatch: mapDispatch,
            } = useMapHook;

            // TODO: test if this is really necessary:
            xyz.map.getInteractions().forEach((interaction) => {
              if (interaction instanceof Draw) {
                interaction.setActive(false);
                draftContributionLayer.getSource().clear();
              }
            });

            await contribution.saveMapContributionPg({
              mapPageContent: mapPage.content,
            });

            // TODO: test if this is really necessary:
            mapDispatch({ type: 'CLEAR_ANSWERS' });
            mapDispatch({ type: 'CLEAR_VOICE_ANSWERS' });
            mapDispatch({ type: 'CLEAR_LEFT_PANEL' });
            mapDispatch({
              type: 'SET_CONTRIBUTION_FLOW_STARTED',
              payload: false,
            });
          }
          return router.push(`/cf3/thanks?slug=${proposalSlug}`);
        }
        console.log('im in :', actualStep, 'going to:', newStep);
        updateStepsOnQuery(newStep);
        setActualStep(newStep);
      };

      const handleNextClick = async () => {
        // await validateEmail();
        // if (emailInput && !emailValid) return;
        changeStep('forwards');
      };

      const handleBackClick = () => {
        changeStep('backwards');
      };
      const handleConsentsToggle = async (
        _e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
      ) => {
        const userConsents = await projectConsents.checkExistentConsents();
        const updatedConsents = defaultConsents.map((consent) => {
          const existingConsent = userConsents.find(
            (c) => c.type === consent.type
          );
          if (!existingConsent && checked) {
            projectConsents.createConsent({ ...consent, enabled: checked });
            return { ...consent, enabled: checked };
          } else {
            projectConsents.updateConsent(existingConsent, {
              enabled: checked,
            });
            return { ...existingConsent, enabled: checked };
          }
        });

        setUserHasAllDefaultConsents(updatedConsents.every((c) => c.enabled));
      };
      const emailInputChange = (email: string) => {
        if (emailInput === email) return;
        setEmailInput(email);
        setSubmitWithForce(false);
      };

      const emailValidation = async (email?: string) => {
        const regexValidation = validateEmailRegex(email);

        if (!regexValidation) {
          setEmailValidationStatus({
            type: 'error',
            message: t('This does not appear to be a valid email address'),
          });
          return false;
        }
        const sendGridValidation = await syntaxValidationRequest({
          data: email,
        }).then(parseSyntaxValidationResult(t));
        if (sendGridValidation) {
          setEmailValidationStatus(sendGridValidation);
          setSubmitWithForce(sendGridValidation.type === 'warning');
        }
        return !sendGridValidation;
      };

      const validateEmail = async (email?: string) => {
        const _email = email || emailInput;
        if (!email) {
          setEmailValidationStatus(null);
          setEmailValid(true);
          return;
        }
        setEmailValid(false); // prevent continue until request is completed
        const ignoreValidation = !email || submitWithForce;
        const emailValidationRes = ignoreValidation
          ? true
          : await emailValidation(_email); /* proper validation */
        setEmailValid(emailValidationRes);
        // }
        if (emailValid) {
          contribution.assignUser(null, _email);
        }
      };

      return (
        <ProposalPageContainer
          data-testid="cf3-container"
          data-contribution-id={contribution?._id}
          data-actual-step={actualStep}
          data-user-id={contribution?.userId}
          data-consents={projectConsents?.consents.map((c) => c._id).join(', ')}
          data-demographics-id={demographics?._id}
          data-pseudo-demographics-id={
            demographics?.dataHandlers?.pseudoDemographics?._id
          }
        >
          <div>
            <Masthead title={pageData.slug} />
            <NewProgressBarCf3 currentStep={1} totalSteps={10} minToRead={1} />
          </div>
          <div>
            <SectionWrapperCf3
              currentStep={actualStep}
              possibleSteps={CfOrder}
              isMap={isMap}
            >
              <ContributionFlowSectionsCf3
                steps={sortedSteps}
                proposalSlug={proposalSlug}
                handleChange={handleChange}
                showMinutesLeft={showMinutesLeft}
              />
              <div id="email" className={proposalSlug}>
                <EmailPageCf3
                  customPageProps={emailPage}
                  contribution={contribution}
                  changeStep={changeStep}
                />
              </div>
              <div id="demographics" className={proposalSlug}>
                <DemographicsPageCf3
                  {...demographicsPage}
                  demographics={demographics}
                />
              </div>
              <div id="preferences" className={proposalSlug}>
                <PreferencesPageCf3
                  {...preferencesPage}
                  projectConsents={projectConsents}
                />
              </div>
              <div id="confirm-email" className={proposalSlug}>
                <ConfirmEmailPageCf3
                  {...confirmEmailPage}
                  contribution={contribution}
                />
              </div>
              <div id="empty-answers" className={proposalSlug}>
                <NoHeaderEmptyAnswersPage
                  {...emptyAnswersPage}
                  changeStep={changeStep}
                />
              </div>
            </SectionWrapperCf3>
            {showEmailBlock && (
              <EmailBlock
                data-testid="cf-email-drawer"
                email={emailInput}
                setEmail={emailInputChange}
                confirmEmail={validateEmail}
                emailValidationStatus={emailValidationStatus}
                onConsentsToggle={handleConsentsToggle}
                consentsCheckboxChecked={userHasAllDefaultConsents}
                disableConsentsCheckbox={!contribution?.userId}
              />
            )}
          </div>
          {showButtons && (
            <NavigationButtons
              handleNextClick={handleNextClick}
              handleBackClick={handleBackClick}
              disableButtons={disableButtons}
              hideButtons={hideButtons}
            />
          )}
        </ProposalPageContainer>
      );
    }
  );
