import * as React from 'react';
import styled from 'styled-components';
import { ProgressBarTypes } from '../ProgressBar';

const NewProgressBarCf3: React.FC<ProgressBarTypes> = ({
  /**
   * minToRead,
   * showMinutesLeft,
   */
  currentStep,
  totalSteps,
}: ProgressBarTypes) => {
  const stepsPercentage = (currentStep * 100) / totalSteps;

  return (
    <Wrapper data-testid="proposal-new-progress-bar-cf3">
      <div>
        <NewProgressBarContainer>
          <NewProgressBar width={stepsPercentage}>
            {`${stepsPercentage}%`}
          </NewProgressBar>
        </NewProgressBarContainer>
      </div>
    </Wrapper>
  );
};

export { NewProgressBarCf3 };

const NewProgressBarContainer = styled.div`
  color: #000 !important;
  background-color: #f1f1f1 !important;
`;

const NewProgressBar = styled.div<{ width: number }>`
  padding: 0.01em 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  clear: both;
  color: #fff !important;
  background-color: ${({ theme }) => theme.colors.green[500]} !important;
  overflow: hidden;
  text-align: center;
  display: block;
  width: ${({ width }) => `${width}%`};
`;

const Wrapper = styled.div`
  margin: -1rem 0 0.4rem;

  @media print {
    display: none;
  }
`;
