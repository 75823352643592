import { gql } from '@apollo/client';
import { DemographicsAcorn } from 'Shared/types/demographics';
import getClient from 'Shared/utils/apollo-client';

export const UPDATE_ACORN_DEMOGRAPHICS = gql`
  mutation UpdateDemographicsAcorn($demographicsId: String, $updates: JSON) {
    updateDemographicsAcorn(demographicsId: $demographicsId, updates: $updates)
  }
`;

export const updateAcornDemographics = async (
  demographicsId: string,
  updates: Partial<DemographicsAcorn>,
  apiToken: string
) => {
  const client = getClient(apiToken);
  const {
    data: { updateDemographicsAcorn },
  } = await client.mutate({
    mutation: UPDATE_ACORN_DEMOGRAPHICS,
    variables: {
      demographicsId: demographicsId,
      updates: updates,
    },
  });
  return updateDemographicsAcorn;
};
