import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiCheckbox from '@material-ui/core/Checkbox';

export const OptionContainer = styled.div<{ active?: boolean }>`
  ${({ active, theme }) => css`
    background-color: ${active
      ? theme.colorMappings.consentsOptionActiveBackground
      : theme.colorMappings.consentsOptionInactiveBackground};
    display: flex;
    margin: 1rem 0;
    padding: 0 0.25rem 0.3125rem;
    border: 0.125rem solid
      ${active ? theme.colors.green[100] : theme.colors.grey[200]};
    border-radius: 0.1875rem;
    cursor: pointer;

    &.hasFocus {
      border: 0.125rem solid ${theme.colorMappings.focusHighlight};
    }

    p.MuiTypography-root {
      color: ${active ? theme.colors.green[900] : theme.colors.black[200]};
    }
  `};
`;

export const OptionCheckbox = styled(({ ...props }) => (
  <MuiCheckbox {...props} />
))`
  ${({ active, theme }) => css`
    padding: 0.875rem 1rem;
    position: absolute;

    svg {
      rect,
      path {
        stroke: ${active ? theme.colors.green[900] : theme.colors.grey[500]};
      }
    }

    &:hover {
      background-color: transparent;

      &.Mui-checked {
        background-color: transparent;
      }
    }
  `};
`;

export const OptionHeader = styled.div<{ active?: boolean }>`
  ${({ active, theme }) => css`
    display: flex;
    align-items: center;
    padding-bottom: 0.3125rem;

    svg {
      min-width: 1rem;

      path {
        fill: ${active ? theme.colors.green[900] : theme.colors.black[200]};
      }
    }

    .MuiTypography-root {
      padding-${theme.direction === 'rtl' ? 'right' : 'left'}: 0.3125rem;
    }
  `};
`;

export const OptionContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.direction === 'rtl'
      ? '1.125rem 4rem 0.75rem 1rem'
      : '1.125rem 1rem 0.75rem 4rem'};

    .MuiTypography-root {
      line-height: 1.3125rem;
    }
  `}
`;

export const RowOptionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  & > p {
    font-size: 1rem;
    font-weight: 400;
  }
  a {
    text-decoration: underline;
  }
`;

export const OptionCheckboxCf3 = styled(({ ...props }) => (
  <MuiCheckbox {...props} />
))`
  ${({ active, theme }) => css`
    padding: 0.875rem 1rem;
    // position: absolute;

    svg {
      rect,
      path {
        stroke: ${active ? theme.colors.green[900] : theme.colors.grey[500]};
      }
    }

    &:hover {
      background-color: transparent;

      &.Mui-checked {
        background-color: transparent;
      }
    }
  `};
`;
