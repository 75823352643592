import { gql } from '@apollo/client';
import { Contribution } from 'Shared/types/contribution';
import getClient from 'Shared/utils/apollo-client';

export const UPDATE_ACORN_CONTRIBUTION = gql`
  mutation UpdateAcornContribution($data: JSON, $contributionId: String) {
    updateAcornContribution(data: $data, contributionId: $contributionId) {
      success
    }
  }
`;

export const updateAcornContribution = async (
  contributionId: string,
  content: Partial<Contribution<'comment'>>,
  apiToken: string
) => {
  const client = getClient(apiToken);
  const { data } = await client.mutate({
    mutation: UPDATE_ACORN_CONTRIBUTION,
    variables: {
      data: content,
      contributionId: contributionId,
    },
  });
  return data;
};
