import { gql } from '@apollo/client';
import getClient from 'Shared/utils/apollo-client';

export const GET_PSEUDO_BY_DEMOGRAPHICS_ID = gql`
  query Query($demographicsId: String) {
    getPseudoByDemographicsId(demographicsId: $demographicsId)
  }
`;

export const getPseudoByDemographicsId = async (
  demographicsId: string,
  apiToken: string
) => {
  const client = getClient(apiToken);
  const {
    data: { getPseudoByDemographicsId },
  } = await client.mutate({
    mutation: GET_PSEUDO_BY_DEMOGRAPHICS_ID,
    variables: {
      demographicsId,
    },
  });
  return getPseudoByDemographicsId;
};
