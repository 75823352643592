import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  h1,
  h4,
  p {
    margin: 0;
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  width: 37.5rem;

  & > h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.3;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const EmailBlock = styled.div`
  ${({ theme }) => css`
    width: 70%;
    border-radius: 0.375rem;
    background: ${theme.colors.white[500]};
    box-shadow: 0.0625rem 0.1875rem 0.5rem 0rem rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 2rem 2rem 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  `}
`;

export const InfoBlock = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 70%;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 0.375rem;
    background: ${theme.colorMappings.controlCenterContentBackground};
    & > h4 {
      font-size: 1rem;
    }
  `}
`;

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.625rem;
  & > p {
    font-size: 0.875rem;
    width: 90%;
  }
`;

export const ConsentCheckbox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    & > p {
      font-size: 0.875rem;
      margin: 0;
      width: 90%;
      & > a {
        color: ${theme.colors.green['A700']};
      }
    }
    .MuiSvgIcon-root {
      fill: ${theme.colors.green['A700']} !important;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  `}
`;

export const ConfirmButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding: 0.8125rem 0.5625rem;
    align-items: center;
    justify-content: center;
    gap: 0.4744rem;
    border-radius: 0.1779rem;
    background: ${theme.colors.green['A700']};
    color: ${theme.colors.white[500]};
    border: none;
    width: 100%;
    text-align: center;
    text-wrap: nowrap;

    font-size: 1rem;
    font-weight: 600;
    &:disabled {
      background: ${theme.colors.grey[500]};
      color: ${theme.colors.white[500]};

      &:hover {
        cursor: not-allowed;
      }
    }
    &:hover {
      cursor: pointer;
    }
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      font-size: 0.875rem;
    }
  `}
`;

export const EmailBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
`;
export const EmailIconWithText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const EmailTopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & > label {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 0rem;
  }
`;
